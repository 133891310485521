<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <div class="top-part">
        <i class="el-icon-caret-left" @click="upperPage"></i>
        <span>{{companyName+'-'+realName}}</span>
      </div>
      
      <!--表格-->
      <el-table
          :data="membershipList"
          border
          style="width: 99%;margin: 0 auto"
      >
        <!--缴费时间-->
        <el-table-column
            prop="createTime"
            :label="$t('PayTime')"
        >
        </el-table-column>
        <!--有效期至-->
<!--        <el-table-column
            prop="expireTime"
            :label="$t('Expire')"
        >
        </el-table-column>-->
        <!--缴费金额-->
        <el-table-column
            prop="price"
            :label="$t('PayAmount')"
        >
        </el-table-column>
      </el-table>
      
      <!--分页-->
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="userTotal"
          style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/fetch/api";
//    import axios from 'axios'

import mixin from "@/assets/js/mixin";
import moment from "moment";

export default {
  mixins: [mixin],
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      myRight: data.userPermission,
      noteDialog: false,
      chamberId: data.chamberId,
      adminId: data.id,
      membershipList: [],
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,
      isNormal: true,
      memberNote:'',
      donationTotal:0,
      userId:this.$route.query.id,
      companyName:this.$route.query.companyName,
      realName:this.$route.query.realName,
      
      
      initDonation: {
        page: 1,
        currentAdmin: data.id,
        tenantCode:JSON.parse(localStorage.getItem('cacheChamber')).adminData.tenantCode
      },
  
      formLabelAlign:{
        name:'',
        region:'',
        type:''
      },
      
      annualFee:'1000',
      feeUnit:'USD',
      freeTime:'10',
      freeDay:'天',
      InvoiceDialog:false,
    };
  },
  mounted() {
    this.initUserData(1);
  },
  methods: {
    
    //            初始化用户列表
    initUserData(currentPage) {
      this.currentPage = currentPage;
      this.initDonation.page = currentPage;
      let param = {
        currentAdmin: this.adminId,
        userId:this.userId,
        chamberId:this.chamberId,
        page:currentPage,
        pageSize:this.pageSize
      }
      api.getPerMemberFeeList(param).then((res) => {
        this.membershipList = res.data.pageList;
        this.userTotal = res.data.membershipList.total;
        this.donationTotal = res.data.totalPrice
      });
    },
    
    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initUserData(currentPage);
    },
  
    // 是否需要发票
    needInvoice(yes){
      if(yes){
        return this.$t('NeedInvoice')
      }else{
        return '/'
      }
    },
    
    // 上一页
    upperPage(){
      this.$router.push({name: 'MembershipFee'})
    },
    
    //            -------------------------------------------------增删改查用户-----------------------------------------------
    
    //            搜索用户列表
    searchUserData() {
      // console.log("api调用", this.initDonation);
      this.initUserData(1);
    },
    
  },
};
</script>
<style lang="scss" scoped>

.mainBody {
  width: 1600px;
  margin-top: 5px;
  
  .top-part{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
    justify-content: center;
    position: relative;
    i{
      font-size: 40px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.filterBarPay {
  width: 99%;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  
  .up{
    width: 100%;
    height: 75px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
    background-color: #677d95;
    border-radius: 5px;
    color: white;
    
    input {
      width: 200px;
      height: 30px;
      border: 1px solid white;
      margin: 8px 0;
      vertical-align: middle;
      float: left;
    }
    
    div label {
      margin: 8px 0 8px 10px;
      display: block;
      float: left;
      //line-height: 75px;
    }
    
    .el-button {
      width: 200px;
      height: 50px;
      line-height: 0.4;
      /*line-height: 50px;*/
      background-color: #677d95;
      color: white;
      
      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }
  
  .down{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    background-color: #677d95;
    color: white;
    border-radius: 5px;
    margin: 10px auto;
    
    .el-button {
      width: fit-content;
      height: 30px;
      line-height: 0.4;
      background-color: #677d95;
      color: white;
      
      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }
  
  
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

pre{
  white-space: pre-wrap;
  word-wrap: break-word;
}

.create-activity-dialog{
  width: 95%;
  padding: 20px 30px;
  border: 1px solid #666666;
  min-height: 50px;
  margin: auto;
  
  .el-form-item{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    
    ::v-deep .el-form-item__label{
      min-width: 180px !important;
      float: left;
      text-align:center;
    }
    
    .el-input{
      width: 500px;
    }
    
    .el-textarea{
      width: 500px;
    }
  }
  
  .per-pay{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .el-select{
      width: 100px;
    }
    .pay-amount{
      width: 200px;
      margin: 0 20px;
      .el-input__inner{
        width: 200px!important;
      }
    }
  }
}

.invoice-detail{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #677d95;
  
  .el-input{
    width: 200px;
    margin-right: 10px;
  }
}
</style>

